<template>
    <div class="TeamSlider-team fullwidth">
        <div class="TeamSlider-line">
            <div class="TeamSlider-carousel hidden" id="TeamSliderCarousel">
                <TeamCard
                        :color="member.color"
                        :firstName="member.firstName"
                        :foxPicture="member.foxPicture"
                        :job="member.job"
                        :key="member.index"
                        :lastName="member.lastName"
                        :realPicture="member.realPicture"
                        :wantedFor="member.wantedFor"
                        v-for="member in teamMembers"
                />
            </div>
        </div>
        <div class="TeamSlider-controls hidden" id="TeamSliderControls">
            <div class="TeamSlider-controls_prev">
                <svg class="TeamSlider-svg_prev">
                    <use xlink:href="#symbol-NavArrowLeft"></use>
                </svg>
            </div>
            <div class="TeamSlider-controls_next">
                <svg class="TeamSlider-svg_next">
                    <use xlink:href="#symbol-NavArrowRight"></use>
                </svg>
            </div>
        </div>
        <div class="TeamSlider-bg">
            <div class="big">
                <span>5</span>
            </div>
            <div></div>
            <div></div>
            <div></div>
            <div class="big">
                <span>4</span>
            </div>
            <div></div>
            <div></div>
            <div></div>
            <div class="big">
                <span>3</span>
            </div>
            <div></div>
            <div></div>
            <div></div>
            <div class="big">
                <span>2</span>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { tns } from 'tiny-slider/src/tiny-slider';

    export default {
        name: 'TeamSlider',
        components: {
            'TeamCard': () => import('@/components/TeamCard')
        },
        data() {
            return {
                sliderTimer: null,
                slider: null,
                teamMembers: store.state.teamMembers
            };
        },
        mounted() {
            this.$nextTick(this.prepareSlider);
        },
        destroyed() {
            if (this.slider !== null) {
                this.slider.destroy();
            }
        },
        watch: {
            'slider': function () {
                if (this.slider !== null) {
                    this.showSlider();
                }
            }
        },
        methods: {
            prepareSlider() {
                this.sliderTimer = setInterval(this.initSlider, 1000);
            },
            initSlider() {
                clearInterval(this.sliderTimer);
                const carousel = document.getElementById('TeamSliderCarousel');
                if (carousel === undefined) {
                    return;
                }
                this.slider = tns({
                    container: document.getElementById('TeamSliderCarousel'),
                    controlsContainer: '#TeamSliderControls',
                    slideBy: 'page',
                    loop: false,
                    autoplay: false,
                    autoplayTimeout: 5000,
                    autoplayButton: false,
                    autoplayButtonOutput: false,
                    arrowKeys: true,
                    preventActionWhenRunning: true,
                    items: 1,
                    responsive: {
                        600: {
                            items: 2
                        },
                        1000: {
                            items: 3
                        },
                        1200: {
                            items: 4
                        }
                    },
                    mouseDrag: false,
                    touch: false,
                    speed: 800,
                    nav: false,
                    pagesCached: true,
                    preventScrollOnTouch: 'force'
                    //rewind: true,
                    //gutter: 30,
                    //center: true,
                    //edgePadding: 50,
                });
            },
            showSlider() {
                const carousel = document.getElementById('TeamSliderCarousel');
                const controls = document.getElementById('TeamSliderControls');
                if (carousel !== undefined) {
                    carousel.classList.remove('hidden');
                }
                if (controls !== undefined) {
                    controls.classList.remove('hidden');
                }
            }
        }
    };
</script>
